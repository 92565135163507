import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, type OnInit, inject } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, type Params, Router, RouterLink } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { type Observable, debounceTime, distinctUntilKeyChanged, map } from 'rxjs';

import { CampaignStatus } from '@clover/campaigns/models/campaign';
import { campaignStatusRoutesMap, campaignStatusToRouteParam } from '@clover/campaigns-v2/campaigns-list.guard';
import { FilterableCampaignStatus } from '@clover/campaigns-v2/state/campaigns-state.model';
import { LoadCampaigns } from '@clover/campaigns-v2/state/campaigns.actions';
import { CampaignsSelectors } from '@clover/campaigns-v2/state/campaigns.selectors';
import type { CampaignsSortingProperty } from '@clover/campaigns-v2/state/campaigns.service';
import { sortDirectionToPagingOrder } from '@clover/core/helpers/paging';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import type { SelectItem } from '@design/forms/select/select-item/select-item';
import { SelectItemComponent } from '@design/forms/select/select-item/select-item.component';
import { SelectComponent } from '@design/forms/select/select.component';
import type { SortDirection } from '@design/table/table';
import { TableComponent } from '@design/table/table.component';
import { ThComponent } from '@design/table/th/th.component';
import { TrComponent } from '@design/table/tr/tr.component';

import { CampaignTableRowComponent } from './campaign-table-row/campaign-table-row.component';

export const statusSelectItems: SelectItem<FilterableCampaignStatus>[] = [
  {
    id: 'all',
    title: 'All campaigns',
    payload: FilterableCampaignStatus.All,
  },
  {
    id: 'completed',
    title: 'Completed',
    payload: FilterableCampaignStatus.Completed,
  },
  {
    id: 'in-progress',
    title: 'In Progress',
    payload: FilterableCampaignStatus.InProgress,
  },
  {
    id: 'closed',
    title: 'Closed',
    payload: FilterableCampaignStatus.Closed,
  },
  {
    id: 'draft',
    title: 'Draft',
    payload: FilterableCampaignStatus.Draft,
  },
];

@UntilDestroy()
@Component({
  selector: 'cc-campaigns-table',
  standalone: true,
  imports: [
    TableComponent,
    AsyncPipe,
    TrComponent,
    ThComponent,
    SelectComponent,
    SelectItemComponent,
    ReactiveFormsModule,
    RouterLink,
    CampaignTableRowComponent,
  ],
  templateUrl: './campaigns-table.component.html',
  styleUrl: './campaigns-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CampaignsTableComponent implements OnInit {
  campaigns$ = inject(Store).select(CampaignsSelectors.campaigns);
  campaignsCount$ = inject(Store).select(CampaignsSelectors.campaignsCount);
  loadingStatus$ = inject(Store).select(CampaignsSelectors.campaignsLoadingStatus);
  sortingOptions$ = inject(Store).select(CampaignsSelectors.campaignsSortingOptions);

  protected completionSortingOrder$: Observable<SortDirection>;
  protected nameSortingOrder$: Observable<SortDirection>;
  protected deliveredSortingOrder$: Observable<SortDirection>;

  protected queryFormControl = new FormControl<string>('');
  protected statusSelectFormControl = new FormControl<SelectItem<FilterableCampaignStatus>>(undefined);

  protected readonly ButtonSize = ButtonSize;
  protected readonly ButtonType = ButtonType;
  protected readonly CampaignStatus = CampaignStatus;
  protected readonly FilterableCampaignStatus = FilterableCampaignStatus;

  protected readonly statusSelectItems = statusSelectItems;

  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly store = inject(Store);

  ngOnInit(): void {
    this.initQueryFilter();
    this.initStatusFilter();

    this.completionSortingOrder$ = this.sortingOptions$.pipe(
      map((sortingOptions) => (sortingOptions.orderBy === 'completionRate' ? sortingOptions.order : null)),
    );

    this.nameSortingOrder$ = this.sortingOptions$.pipe(
      map((sortingOptions) => (sortingOptions.orderBy === 'name' ? sortingOptions.order : null)),
    );

    this.deliveredSortingOrder$ = this.sortingOptions$.pipe(
      map((sortingOptions) => (sortingOptions.orderBy === 'publishedAt' ? sortingOptions.order : null)),
    );
  }

  changeSort(property: CampaignsSortingProperty, direction: SortDirection): void {
    this.store.dispatch(
      new LoadCampaigns({
        sortingOptions: {
          order: sortDirectionToPagingOrder(direction),
          orderBy: property,
        },
      }),
    );
  }

  private initQueryFilter(): void {
    this.queryFormControl.setValue(this.activatedRoute.snapshot.queryParams['query'] || '', { emitEvent: false });

    this.queryFormControl.valueChanges.pipe(untilDestroyed(this), debounceTime(300)).subscribe((query) => {
      this.setRouteParams({ query: query || undefined });
    });
  }

  private initStatusFilter(): void {
    this.activatedRoute.queryParams
      .pipe(untilDestroyed(this), distinctUntilKeyChanged('status'))
      .subscribe(({ status: statusParam }) => {
        const status = campaignStatusRoutesMap.get(statusParam);
        const selectItem = statusSelectItems.find((item) => item.payload === status);

        this.statusSelectFormControl.setValue(selectItem, { emitEvent: false });
      });

    this.statusSelectFormControl.valueChanges.pipe(untilDestroyed(this)).subscribe((selectItem) => {
      const status = selectItem.payload;
      const statusParam = campaignStatusToRouteParam(status);

      this.setRouteParams({ status: statusParam });
    });
  }

  private setRouteParams(params: Params): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: params,
      queryParamsHandling: 'merge',
    });
  }
}
