import { inject, Injectable } from '@angular/core';
import { Router, UrlSerializer } from '@angular/router';
import { map, type Observable } from 'rxjs';

import {
  type TaskCommunicationRoleResponse,
  type TaskPerformerResponse,
  type TaskPerformerCompanyResponse,
  mapTaskPerformerCompany,
  mapTaskRole,
  mapTaskPerformer,
} from '@clover/conversations-v4/tasks/tasks.service';
import {
  getOffsetPagingOptionsParams,
  type OffsetPagingOptions,
  type OffsetPagingWrapper,
} from '@clover/core/helpers/paging';
import { HttpService } from '@clover/core/services/http.service';

import type { CampaignAudience, CampaignStatus } from './campaigns-state.model';

interface CampaignAudienceResponse {
  id: number;
  name: string;
  status: CampaignStatus;
  completion: number;
  assignedTo: {
    communicationRoles: TaskCommunicationRoleResponse[];
    users: TaskPerformerResponse[];
  };
  assignedToCompany: TaskPerformerCompanyResponse;
  assignedToCompanyHeadquartersAddress: never;
  assignedAt: string;
}

function mapCampaignAudience(a: CampaignAudienceResponse): CampaignAudience {
  return {
    id: a.id,
    name: a.name,
    status: a.status,
    completion: a.completion,
    assigneeRoles: a.assignedTo.communicationRoles.map(mapTaskRole),
    assigneeUsers: a.assignedTo.users ? a.assignedTo.users.map(mapTaskPerformer) : [],
    assigneeCompany: mapTaskPerformerCompany(a.assignedToCompany),
    assignedAt: a.assignedAt,
  };
}

export type CampaignsAudienceSortingProperty = 'name' | 'completionRate';

@Injectable({
  providedIn: 'root',
})
export class AudienceService {
  private readonly http = inject(HttpService);
  private readonly router = inject(Router);
  private readonly serializer = inject(UrlSerializer);

  searchCampaignAudience(
    campaignId: number,
    query: string,
    pagingOptions?: OffsetPagingOptions<CampaignsAudienceSortingProperty>,
  ): Observable<OffsetPagingWrapper<CampaignAudience>> {
    const urlTree = this.router.createUrlTree(['api', 'userTaskCampaigns', campaignId, 'audience', 'search'], {
      queryParams: {
        query: query || undefined,
        ...getOffsetPagingOptionsParams(pagingOptions),
      },
    });

    const path = this.serializer.serialize(urlTree);
    return this.http.getV2<OffsetPagingWrapper<CampaignAudienceResponse>>(path).pipe(
      map((response) => ({
        ...response,
        data: response.data.map(mapCampaignAudience),
      })),
    );
  }
}
