import { Selector } from '@ngxs/store';

import type { SortingOptions } from '@clover/core/helpers/paging';

import type { CampaignPreview, CampaignsStateModel, FilterableCampaignStatus } from './campaigns-state.model';
import type { CampaignsSortingProperty } from './campaigns.service';
import { CampaignsState } from './campaigns.state';

export class CampaignsSelectors {
  @Selector([CampaignsState])
  static campaigns(state: CampaignsStateModel): CampaignPreview[] {
    return state.campaigns?.data || [];
  }

  @Selector([CampaignsState])
  static campaignsCount(state: CampaignsStateModel): number {
    return state.campaigns?.total || 0;
  }

  @Selector([CampaignsState])
  static campaignsLoadingStatus(state: CampaignsStateModel): 'void' | 'loading' | 'loaded' | 'error' | 'loading-next' {
    return state.loadingStatus;
  }

  @Selector([CampaignsState])
  static campaignsQuery(state: CampaignsStateModel): string {
    return state.query;
  }

  @Selector([CampaignsState])
  static campaignsStatus(state: CampaignsStateModel): FilterableCampaignStatus {
    return state.status;
  }

  @Selector([CampaignsState])
  static campaignsSortingOptions(state: CampaignsStateModel): SortingOptions<CampaignsSortingProperty> {
    return state.sorting;
  }
}
