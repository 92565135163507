<cc-table
  [ccSearchFormControl]="queryFormControl"
  [ccSearchable]="true"
  [ccStickyHeader]="true"
  ccPadding="medium"
  ccSearchPlaceholder="Search campaigns..."
  [ccEmptyRowTemplate]="emptyRowTemplate"
>
  <ng-container slot="cc-table-title">
    @if (campaignsCount$ | async; as campaignsCount) {
      {{ campaignsCount }} {{ campaignsCount === 1 ? 'campaign' : 'campaigns' }}
    }
  </ng-container>

  <ng-container slot="сс-table-custom-actions">
    <cc-select [formControl]="statusSelectFormControl">
      <ng-container ccOptions>
        @for (option of statusSelectItems; track option.id) {
          <cc-select-item
            [id]="option.id"
            [value]="option.title"
            [payload]="option.payload"
          />
        }
      </ng-container>
    </cc-select>
  </ng-container>

  <ng-container slot="cc-table">
    <thead>
      <tr ccTr>
        <th
          ccTh
          ccThTooltip="Completion"
          (ccSortChange)="changeSort('completionRate', $event)"
          [ccSortDirection]="completionSortingOrder$ | async"
          [ccSortable]="true"
          ccWidth="56px"
        ></th>

        <th
          ccTh
          (ccSortChange)="changeSort('name', $event)"
          [ccSortDirection]="nameSortingOrder$ | async"
          [ccSortable]="true"
        >
          Campaign
        </th>

        <th
          ccTh
          (ccSortChange)="changeSort('publishedAt', $event)"
          [ccSortDirection]="deliveredSortingOrder$ | async"
          [ccSortable]="true"
          ccWidth="184px"
        >
          Delivered
        </th>

        <th
          ccTh
          ccWidth="48px"
        ></th>
      </tr>
    </thead>

    <tbody>
      @for (campaign of campaigns$ | async; track campaign.id) {
        <tr
          ccTr
          [ccClickable]="true"
          [routerLink]="['/', 'campaigns', campaign.id]"
        >
          <cc-campaign-table-row [campaign]="campaign" />
        </tr>
      }
    </tbody>
  </ng-container>
</cc-table>

<ng-template #emptyRowTemplate> There are no campaigns to display. </ng-template>
