import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { CampaignStatus } from '@clover/campaigns-v2/state/campaigns-state.model';

const backgroundColors: Partial<Record<CampaignStatus, string>> = {
  [CampaignStatus.Completed]: 'var(--comp-status-chip-color-completed-bg, #D6F9EA)',
  [CampaignStatus.InProgress]: 'var(--comp-status-chip-color-in-progress-bg, #E3F4FC)',
  [CampaignStatus.Closed]: 'var(--comp-status-chip-color-closed-bg, #DCDFE3)',
  [CampaignStatus.Draft]: 'var(--comp-status-chip-color-no-connection-bg, #FBE4E9)',
};

const foregroundColors: Partial<Record<CampaignStatus, string>> = {
  [CampaignStatus.Completed]: 'var(--comp-status-chip-color-completed-fg, #08A262)',
  [CampaignStatus.InProgress]: 'var(--comp-status-chip-color-in-progress-fg, #04689A)',
  [CampaignStatus.Closed]: 'var(--comp-status-chip-color-closed-fg, #5E6A75)',
  [CampaignStatus.Draft]: 'var(--comp-status-chip-color-no-connection-fg, #AB2345)',
};

const defaultBackgroundColor = 'var(--comp-status-chip-color-closed-bg, #DCDFE3)';
const defaultForegroundColor = 'var(--comp-status-chip-color-closed-fg, #5E6A75)';

@Component({
  selector: 'cc-campaign-status-chip',
  standalone: true,
  templateUrl: './campaign-status-chip.component.html',
  styleUrls: ['./campaign-status-chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CampaignStatusChipComponent {
  @Input()
  status: CampaignStatus;

  protected readonly CampaignStatus = CampaignStatus;

  get backgroundColor(): string {
    return backgroundColors[this.status] ?? defaultBackgroundColor;
  }

  get foregroundColor(): string {
    return foregroundColors[this.status] ?? defaultForegroundColor;
  }
}
