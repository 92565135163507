import { CdkScrollable } from '@angular/cdk/scrolling';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { NgScrollbar } from 'ngx-scrollbar';
import { catchError, EMPTY, take, tap } from 'rxjs';

import { downloadPlainText } from '@clover/core/helpers/download-plain-text';
import { CdkPortalService } from '@clover/core/services/cdk-portal.service';
import { DateService } from '@clover/core/services/date.service';
import { ToastType } from '@design/overlays/toast/toast';

import { CampaignsHeaderComponent } from './campaigns-header/campaigns-header.component';
import { CampaignsTableComponent } from './campaigns-table/campaigns-table.component';
import { CampaignsExportService } from '../state/campaigns-export.service';
import { LoadNextCampaigns } from '../state/campaigns.actions';

@Component({
  selector: 'cc-campaigns-list',
  standalone: true,
  imports: [CampaignsHeaderComponent, CampaignsTableComponent, NgScrollbar, InfiniteScrollDirective, CdkScrollable],
  templateUrl: './campaigns-list.component.html',
  styleUrl: './campaigns-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CampaignsListComponent {
  private readonly store = inject(Store);
  private readonly campaignsExportService = inject(CampaignsExportService);
  private readonly portalService = inject(CdkPortalService);
  private readonly dateService = inject(DateService);
  private readonly translateService = inject(TranslateService);

  loadNextCampaigns(): void {
    this.store.dispatch(new LoadNextCampaigns());
  }

  handleCampaignsExport(): void {
    this.portalService.presentToast('Exporting campaigns...', ToastType.Info);

    this.campaignsExportService
      .exportAllCampaigns()
      .pipe(
        take(1),
        tap((csv) => {
          const timezoneNow = this.dateService.representLocalDateInProfileTimezoneDate(new Date());
          const filename = this.translateService.instant('campaignsPage.exportFileName', {
            date: this.dateService.format(timezoneNow, {
              date: 'numeric',
            }),
          });

          downloadPlainText(csv, `${filename}.csv`);
          this.portalService.presentToast('Campaigns exported successfully', ToastType.Success);
        }),
        catchError(() => {
          this.portalService.presentToast('Failed to export campaigns', ToastType.Error);
          return EMPTY;
        }),
      )
      .subscribe();
  }
}
