<td
  ccTd
  ccVerticalAlignment="center"
  ccHorizontalAlignment="start"
  style="padding-right: 4px !important"
>
  <nz-progress
    [nzFormat]="percentageFormatter"
    [nzPercent]="campaign().completion"
    [nzStrokeColor]="completionColor()"
    nzType="circle"
    [nzWidth]="56"
  />
</td>

<td ccTd>
  <div class="campaign-details-cell">
    <div class="campaign-details-cell__main">
      <div class="campaign-details-cell__main-title">
        {{ campaign().name }}
      </div>

      <cc-campaign-status-chip
        [status]="campaign().status"
        class="campaign-details-cell__main-status"
      />
    </div>

    <div class="campaign-details-cell__metadata">
      <span class="campaign-details-cell__metadata-companies">
        Assigned to
        @if (!campaign().previewCompany) {
          {{ campaign().companiesCount }} {{ campaign().companiesCount > 1 ? 'companies' : 'company' }}
        } @else if (campaign().companiesCount > 1) {
          {{ campaign().previewCompany.name }}, +{{ campaign().companiesCount - 1 }} more
        } @else {
          {{ campaign().previewCompany.name }}
        }
      </span>
    </div>
  </div>
</td>

<td
  ccTd
  ccVerticalAlignment="center"
  ccHorizontalAlignment="start"
>
  <div class="campaign-delivered-cell">
    @if (campaign().deliveredAt; as deliveredAt) {
      {{ deliveredAt | ccDateFormatDistance }} ago
    } @else {
      –
    }
  </div>
</td>

<td
  ccTd
  ccVerticalAlignment="center"
  ccHorizontalAlignment="start"
>
  <div class="campaign-action-cell">
    @if ([CampaignStatus.InProgress, CampaignStatus.Draft].includes(campaign().status)) {
      <div #dropdownTrigger>
        <app-button
          [active]="dropdownVisible"
          (click)="$event.stopPropagation(); dropdownVisible = !dropdownVisible"
          ccTooltip="More actions"
          [tooltipAlignment]="TooltipAlignment.End"
          [type]="ButtonType.Ghost"
          [size]="ButtonSize.Small"
        >
          <i
            class="icon-more"
            slot="ghost-icon"
          ></i>
        </app-button>
      </div>

      <ng-template
        (detach)="dropdownVisible = false"
        (overlayOutsideClick)="dropdownVisible = getOverlayVisibilityAfterOutsideClick($event, dropdownTrigger)"
        [cdkConnectedOverlayOpen]="dropdownVisible"
        [cdkConnectedOverlayOrigin]="dropdownTrigger"
        [cdkConnectedOverlayPositions]="dropdownPositionStrategy"
        cdkConnectedOverlay
      >
        <cc-dropdown width="160px">
          @if (campaign().status === CampaignStatus.InProgress) {
            <cc-dropdown-action (click)="sendReminders(); dropdownVisible = false">
              <ng-container slot="title"> Send reminders </ng-container>
            </cc-dropdown-action>

            <cc-dropdown-divider />

            <cc-dropdown-action (click)="closeCampaign(); dropdownVisible = false">
              <ng-container slot="title"> Close campaign </ng-container>
            </cc-dropdown-action>
          }

          @if (campaign().status === CampaignStatus.Draft) {
            <cc-dropdown-action (click)="publishDraftCampaign(); dropdownVisible = false">
              <ng-container slot="title"> Publish </ng-container>
            </cc-dropdown-action>

            <cc-dropdown-action [routerLink]="['/', 'campaigns', campaign().id, 'edit']">
              <ng-container slot="title"> Edit </ng-container>
            </cc-dropdown-action>

            <cc-dropdown-divider />

            <cc-dropdown-action (click)="deleteDraftCampaign(); dropdownVisible = false">
              <ng-container slot="title"> Delete </ng-container>
            </cc-dropdown-action>
          }
        </cc-dropdown>
      </ng-template>
    }
  </div>
</td>
