<header class="campaigns-header">
  <div class="campaigns-header__details">
    <h2 class="campaigns-header__details-title">Campaigns</h2>

    <p class="campaigns-header__details-description">
      Create, manage, and track campaigns that are sent to your trading partners for the purposes of collecting
      information, sharing documents for signature, or general communication.
    </p>
  </div>

  <div class="campaigns-header__actions">
    <app-button
      [type]="ButtonType.Secondary"
      [size]="ButtonSize.Small"
      (click)="export.emit()"
    >
      <i
        slot="start-icon"
        class="icon-download-csv"
      >
      </i>

      Export
    </app-button>

    <app-button
      [type]="ButtonType.Primary"
      [size]="ButtonSize.Small"
      [routerLink]="['/', 'campaigns', 'create']"
    >
      New campaign
    </app-button>
  </div>
</header>
