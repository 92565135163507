<div class="campaigns-list">
  <ng-scrollbar
    (scrolled)="loadNextCampaigns()"
    [infiniteScrollDistance]="4"
    [infiniteScrollThrottle]="0"
    [infiniteScrollUpDistance]="4"
    [scrollWindow]="false"
    cdkScrollable
    infiniteScroll
    class="campaigns-list__list"
  >
    <div class="campaigns-list__list-wrapper">
      <cc-campaigns-header
        (export)="handleCampaignsExport()"
        class="campaigns-list__list-wrapper-header"
      />
      <cc-campaigns-table class="campaigns-list__list-wrapper-list" />
    </div>
  </ng-scrollbar>
</div>
