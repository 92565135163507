import { inject } from '@angular/core';
import { CanActivateFn, createUrlTreeFromSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import * as R from 'ramda';

import { FilterableCampaignStatus } from './state/campaigns-state.model';
import { CampaignsSelectors } from './state/campaigns.selectors';

export enum CampaignStatusRouteParam {
  All = 'all',
  Completed = 'completed',
  InProgress = 'in-progress',
  Closed = 'closed',
  Draft = 'draft',
}

export const campaignStatusRoutesMap = new Map<CampaignStatusRouteParam, FilterableCampaignStatus>([
  [CampaignStatusRouteParam.All, FilterableCampaignStatus.All],
  [CampaignStatusRouteParam.Completed, FilterableCampaignStatus.Completed],
  [CampaignStatusRouteParam.InProgress, FilterableCampaignStatus.InProgress],
  [CampaignStatusRouteParam.Closed, FilterableCampaignStatus.Closed],
  [CampaignStatusRouteParam.Draft, FilterableCampaignStatus.Draft],
]);

export const campaignsListGuard: CanActivateFn = (route) => {
  const {
    queryParams: { query: queryParam, status: statusParam },
  } = route;

  const [queryValid, query] = validateQueryParam(queryParam);
  const [statusValid, status] = validateStatusParam(statusParam);

  if (queryValid && statusValid) return true;

  const segmentParams = {
    query,
    status,
  };

  // Remove undefined parameters
  const definedParams = R.pickBy((value) => !!value, segmentParams);

  return createUrlTreeFromSnapshot(route, [], definedParams);
};

function validateQueryParam(param: string | undefined): [boolean, string] {
  return [true, param || inject(Store).selectSnapshot(CampaignsSelectors.campaignsQuery)];
}

function validateStatusParam(param: CampaignStatusRouteParam | undefined): [boolean, string] {
  const valid = campaignStatusRoutesMap.has(param);
  return [
    valid,
    valid ? param : campaignStatusToRouteParam(inject(Store).selectSnapshot(CampaignsSelectors.campaignsStatus)),
  ];
}

export function campaignStatusToRouteParam(status: FilterableCampaignStatus): CampaignStatusRouteParam {
  for (const [param, value] of campaignStatusRoutesMap) {
    if (value === status) return param;
  }

  return CampaignStatusRouteParam.All;
}
